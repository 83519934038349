// require('normalize.css/normalize.css');
require('./styles/index.scss');


Vue.component('modal', {
    template: '#modal-template'
  });


Vue.component('slider', {

    data: function () {

        return {
            timeBetweenSlides: 5000,
            timeBeforeRestart: 3000,
            slides: [],
            activeSlide: null,
            interval: null,
            isPaused: false
        }

    },
    template: `<div><slot></slot></div>`,

    methods: {

        goPrev: function() {

            // Get all the slides again
            this.slides = $(this.$el).children(".slide");

            // Register active slide
            this.activeSlide = $('.active');

            // Move the last slide before the first so the user can keep going backwards
            this.slides.first().before(this.slides.last());
            // Move active class to the left
            this.activeSlide.removeClass('active').prev('.slide').addClass('active');
        },
        goNext: function() {
            
            // Get all the slides again
            this.slides = $(this.$el).children(".slide");

            // Register active slide
            this.activeSlide = $('.active');


            // Move first slide to the end so the user can keep going forward
            this.slides.last().after(this.slides.first());
            // Move active class to the right
            this.activeSlide.removeClass('active').next('.slide').addClass('active');
        },
        initInterval: function() {

            clearInterval(this.interval);
            this.interval = setInterval(() => {
                this.goNext();
            }, this.timeBetweenSlides);
        }

    },


    mounted: function () {

        let self = this;

        this.$el.classList.add("slider");

        // Get all the slides
        self.slides = $(self.$el).children(".slide");

        // Move the last slide before the first so the user is able to immediately go backwards
        self.slides.first().before(self.slides.last());

        $(self.$el).children(".nav").on('click', function() {

            // // Register button
            var button = $(this);
            
            // Next function
            if (button.hasClass('next')) {
                clearInterval(self.interval);
                self.goNext();
            }
            
            // Previous function
            if (button.hasClass('prev')) {
                clearInterval(self.interval);
                self.goPrev();
            }

            setTimeout(() => {
                self.initInterval();
            }, self.timeBeforeRestart);
        });

        self.initInterval();
    }
    
});


Vue.directive('counter', {

    inserted: function (el) {

        let minScroll = $(el).position().top;

        let scrollListener = function() {
            // _.throttle(() => {

                // let visible = $(window).scrollTop() < $(el).offset().top + $(el).height();
                let visible = ($(window).height() + $(window).scrollTop()) > $(el).offset().top;
        
                if (visible) {
    
                    window.removeEventListener("scroll", scrollListener);
    
                    $(el).find("label").each(function() {
    
                        let elemVal = parseInt($(this).attr("rel"));
                        let val = 0;
                
                        let iv = setInterval(() => {
                            if (++val >= elemVal) clearInterval(iv);
                            $(this).html(val);
                        }, 18);
    
                    });
                }
            // }, 30);
        };

        $(el).find("label").each(function() {
            $(this).attr("rel", $(this).html());
            $(this).html("0");
        });

        window.addEventListener('scroll', scrollListener);
    }
    
});


Vue.component('gallery', {

    data: function () {

        return {
            currIndex: 0,
            items: []
        }

    },
    template: `
        <div>
            <slot></slot>
            <div class="gallery-overlay"></div>
            <div class="gallery-container">
                <div class="gallery-rel">
                <button @click="goPrev()">&lt;</button>
                <div class="gallery-pic">
                    <button @click="close()">X</button>
                    <img class="noscale" v-bind:src="items[currIndex]" />
                </div>
                <button @click="goNext()">&gt;</button>
                </div>
            </div>
        </div>
    `,
    methods: {

        openImage() {
            this.$el.getElementsByClassName("gallery-overlay")[0].style.display = "block";
            this.$el.getElementsByClassName("gallery-container")[0].style.display = "block";
            console.log(this.items);
        },
        goPrev: function() {
            if (--this.currIndex < 0) this.currIndex = this.items.length;
            console.log(this.items[this.currIndex]);
        },
        goNext: function() {
            if (++this.currIndex >= this.items.length) this.currIndex = 0;
            console.log(this.items[this.currIndex]);
        },
        close: function() {
            this.$el.getElementsByClassName("gallery-overlay")[0].style.display = "none";
            this.$el.getElementsByClassName("gallery-container")[0].style.display = "none";
        }
    },
    mounted: function () {

        let self = this;
        self.items = [];
        
        $(self.$el).find("li").each(function(index)
        {
            $(this).attr("idx", index);
            self.items.push($(this).attr("rel"));
        });

        $(self.$el).find("li").click(function()
        {
            self.currIndex = $(this).attr("idx");
            self.openImage();
        });

        // self.$el.children
        // this.$el.classList.add("slider");
    }
    
});





var app = new Vue({
    el: '#app',
    data: {

        // modal vars
        showModalBurger : false,
        showModalNewsletter : false
    },
    methods: {

        closeModals() {
            this.showModalBurger =
            this.showModalNewsletter = false;
        },

        // SEARCH
        searchInputEnterPressed(evt) {
            document.location.href = "search?q=" + evt.target.value;
        },

        // OFFRES D'EMPLOI
        selectOffre(id) {
            document.getElementById('selectedoffre').value=id;
            document.getElementsByClassName('candidature')[0].scrollIntoView({ block: 'start',  behavior: 'smooth' });
        }

    }
});



